<template>
    <div class="header">
        <div>
            <span class="curson-helper logo" @click="goToChallenges">
                <b-img
                    :src="branding.primaryLogoUrl"
                    class="logo w-100"
                    alt=" "
                />
            </span>
            <span class="back-responsive" @click="goToChallenges">
                <font-awesome-icon :icon="['fas','chevron-left']" />
                <span class="responsive-text">{{ challenge.multilingualChallenge.config.alias }}</span>
            </span>
        </div>
        <avatar :avatar="player.avatar || null" max-avatar-width="70px" avatar-width="70%" />
        <div class="points-container">
            <span class="points green">{{ player.totalScore }}</span>
            <span class="points-text">{{ $t('taskTopBar.points') }}</span>
        </div>
        <span class="curson-helper d-none d-sm-block" @click="goToChallenges">
            <font-awesome-icon :icon="['fas','home']" class="home" />
            <span class="px-2 back-to-main">{{ $t('taskTopBar.backToMain') }}</span>
        </span>
    </div>
</template>

<script>
    import Avatar from '@/components/Avatar'

    export default {
        name: 'ChallengeTopBar',
        components: { Avatar },
        data() {
            return {
                interval: {},
                secondsLeft: 0
            }
        },
        computed: {
            branding() {
                return this.$store.getters.getBranding
            },
            player() {
                return this.$store.getters.getCurrentUser
            },
            challenge() {
                const challengeId = this.$route.params.challengeId
                return this.$store.getters.availableChallenges.find(challenge => challenge.id === parseInt(challengeId))
            }
        },
        methods: {
            goToChallenges() {
                this.$router.push({ path: `/challenges` })
            }
        }
    }
</script>

<style lang="scss" scoped>
.header {
    background: rgb(0 0 0 / 40%);
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header .logo {
    max-width: 200px;
    display: none;
}

.header .points {
    font-size: 25px;
    vertical-align: text-top;
    font-weight: 700;
    color: var(--secondary-color);
}

.header .points-text {
    font-weight: 700;
    font-size: 0.9375rem;
    color: var(--white);
}

.vertical-align {
    display: flex;
    align-items: center;
    justify-content: center;
}

.points-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
}

.back-responsive {
    font-weight: 700;
    font-size: 35px;
    color: var(--secondary-color);
    cursor: pointer;
}

.responsive-text {
    font-weight: 700;
    font-size: 0.7rem;
    color: var(--white);
}

.curson-helper {
    cursor: pointer;
}

.timer-container {
    display: flex;
    flex-direction: column;
}

.timer {
    font-weight: 700;
    font-size: 25.2px;
    color: var(--secondary-color);
}

.timer-text {
    font-weight: 700;
    font-size: 14px;
    color: var(--white);
}

svg.home path {
    color: var(--white);
}

.back-to-main {
    color: var(--white);
}

@media screen and (min-width: 576px) {
    .back-responsive {
        display: none;
    }
}

@media screen and (min-width: 768px) {
    .points-container {
        display: flex;
    }

    .timer-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;
        justify-content: center;
    }

    .header .logo {
        display: block;
    }
}

</style>
