<template>
    <div v-if="challenge && challenge.type" class="challenge">
        <challenge-top-bar />
        <b-container
            v-if="showInstructions"
            class="instructions-container px-3"
        >
            <b-row>
                <b-col class="margin-elements">
                    <span class="points-text-1 mr-2"><font-awesome-icon :icon="[ 'fas', 'plus-circle' ]" /></span><span class="points-text-2">{{ challenge.points }}</span><span class="ml-2 points-text-1">{{ $t('taskRoute.points') }}</span>
                </b-col>
                <b-col lg="6" class="intro-box">
                    <challenge-intro :challenge="challenge" />
                    <button
                        class="start-button"
                        @click="start()"
                    > {{ buttonText }} </button>
                </b-col>
                <b-col />
            </b-row>
        </b-container>
        <div v-else class="game-wrapper" :class="{'full-height': isGame}">
            <challenge v-if="isGame" />
        </div>
    </div>

</template>

<script>
    import Challenge from '@/components/games/Challenge'
    import ChallengeTopBar from '@/components/ChallengeTopBar'
    import ChallengeIntro from '@/components/ChallengeIntro'

    export default {
        components: {
            Challenge,
            ChallengeTopBar,
            ChallengeIntro
        },
        data() {
            return {
                showInstructions: true
            }
        },
        computed: {
            deviceType() {
                return this.$store.getters.deviceType
            },
            isGame() {
                if (!this.challenge?.type) {
                    return false
                }
                return this.challenge.type.alias.startsWith('GAME')
            },
            challenge() {
                let challengeId = parseInt(this.$route.params.challengeId)
                return this.$store.getters.availableChallenges.find(challenge => challenge.id === challengeId)
            },
            isCheatMode() {
                return this.$store.getters.isCheatMode
            },
            remainingChallengeSessions() {
                return this.$store.getters.getRemainingChallengeSessions
            },
            buttonText() {
                if (!this.challenge?.multilingualChallenge?.config) {
                    return 'NO_TRANSLATION_BUTTON_TEXT'
                }
                return this.challenge.multilingualChallenge.config.challengeIntroButton
            }
        },
        watch: {
            remainingChallengeSessions(newVal) {
                if (newVal === 0) {
                    this.$router.push('/challenges')
                }
            }
        },
        created() {
            if (this.remainingChallengeSessions === 0) {
                this.$router.push('/challenges')
            }
        },
        updated() {
            this.redirectOnInvalidChallenge()
        },
        methods: {
            redirectOnInvalidChallenge() {
                if (!(this.challenge)) {
                    this.$router.push({ name: 'Challenges' })
                }
            },
            start() {
                this.showInstructions = false
            }
        }
    }
</script>

<style lang="scss" scoped>
.challenge {
    flex-grow: 1;
    min-height: 100% !important;
}

.game-wrapper {
    padding-top: 2rem;
}

.game-wrapper.full-height {
    padding-top: 0;
    min-height: 100vh;
}

.intro-box {
    background: rgb(0 0 0 / 40%);
    width: 100%;
    padding: 1rem 0;
    margin: 0 auto;
}

.points-text-1 {
    font-weight: 700;
    color: var(--main-content-text);
    font-size: 1.5rem;
    text-align: center;
}

.points-text-2 {
    font-weight: 700;
    color: var(--secondary-color);
    font-size: 2.25rem;
    text-align: center;
}

.start-button {
    margin: 1rem auto;
    min-width: 120px;
    height: 40px;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 1px;
    box-shadow: 0 4px 20px rgb(0 0 0 / 10%);
    border-radius: 4px;
    border: 0;
    background: var(--secondary-color);
    color: var(--white);
    transition: all 0.2s ease-in-out;
    outline: none;
    display: grid;
    place-items: center;
}

.start-button:active {
    transform: scale(0.9);
}

.start-button.disabled {
    background: var(--disabled-color);
    color: var(--disabled-text-color);
}

.start-button.disabled:active {
    transform: none;
}

.earn-max {
    position: absolute;
    bottom: 1%;
    left: 40%;
    transform: translateX(-50%);
}

@media screen and (min-width: 768px) {
    .instructions-container {
        margin-top: 2rem;
    }

    .start-button {
        margin-top: 2rem;
        width: 160px;
        height: 45px;
    }

    .earn-max {
        position: absolute;
        bottom: 1%;
        left: 50%;
        transform: translateX(-50%);
    }
}

</style>
